import Vue from 'vue'
import App from './App.vue'
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyD3lhdfkxYQsxIhuR2yzAM9Es9dskRUke0",
  authDomain: "jims-cards.firebaseapp.com",
  projectId: "jims-cards",
  storageBucket: "jims-cards.appspot.com",
  messagingSenderId: "1009285216405",
  databaseURL: "https://jims-cards-default-rtdb.firebaseio.com",
  appId: "1:1009285216405:web:a87d20507196a81470839c",
  measurementId: "G-HK51G4WCWV"
}

const app = initializeApp(firebaseConfig)
export const db = getDatabase(app)
getAnalytics(app)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
